import PdfViewerComponent from "./components/PdfViewerComponent";
import "./App.css";

function App() {
  return (
    <div className="App">
      <div className="App-viewer">
        <PdfViewerComponent document={window.fileName} />
      </div>
    </div>
  );
}

export default App;
