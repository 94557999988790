import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://3f8b3feb913eeddc3975c8d2b1713456@o4506373612109824.ingest.sentry.io/4506373628493824",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost"],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = createRoot(document.getElementById("root"));

root.render(<App />);
